import { Modal } from "antd";
import { useTranslation } from "react-i18next";

export default function BaseModal({
  title,
  isVisible,
  onClose,
  onSubmit = () => {},
  isSubmitting,
  submitButtonProps,
  children,
  closeButtonText = "Close",
  submitButtonText = "Submit",
  ...props
}) {
  const { t } = useTranslation();

  return (
    <Modal
      title={t(title)}
      visible={isVisible}
      onOk={onSubmit}
      onCancel={onClose}
      confirmLoading={isSubmitting}
      okButtonProps={submitButtonProps}
      cancelText={t(closeButtonText)}
      okText={t(submitButtonText)}
      {...props}
    >
      {children}
    </Modal>
  );
}
