import { deleteLocalAuthTokens } from "@shared/utils";
import { Button } from "antd";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";

export default function LogoutButton() {
  const { t } = useTranslation();
  const history = useHistory();

  return (
    <Button
      type="danger"
      style={{ marginLeft: "auto" }}
      onClick={() => {
        deleteLocalAuthTokens();
        history.push("/login");
      }}
    >
      {t("Logout")}
    </Button>
  );
}
