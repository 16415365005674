import { PlusOutlined } from "@ant-design/icons";
import { Button } from "antd";
import { useTranslation } from "react-i18next";

export default function CreateButton({ onClick, buttonText }) {
  const { t } = useTranslation();

  return (
    <Button
      type="primary"
      icon={<PlusOutlined />}
      style={{ marginBottom: "2rem" }}
      onClick={onClick}
    >
      {t(buttonText)}
    </Button>
  );
}
