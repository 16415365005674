import { handleMutation, handleQuery } from "@shared/services/api-client";

export const getRecommendedUsers = async (queryParams) =>
  handleQuery({
    resourceUrl: `/user/${queryParams.userId}`,
    apiService: "diagnosis-v2",
  });

/* ---- Queries ---- */
export const fetchDiagnosisUser = async (queryParams) =>
  handleQuery({
    resourceUrl: `/user/${queryParams.userId}`,
    apiService: "diagnosis-v2",
  });

export const fetchNextQuestion = async (queryParams) =>
  handleQuery({
    resourceUrl: `/next/`,
    queryParams,
    apiService: "diagnosis-v2",
  });

/**
 * RESULTS
 */
/**
 * :param: user_id          //"1234"
 * :param: category         //"major" or "middle" or "minor
 */
export const fetchSection1Output = async (queryParams) =>
  handleQuery({
    resourceUrl: `/section1`,
    queryParams,
    apiService: "diagnosis-v2",
  });

/**
 * :param: user_id           //"1234"
 */
export const fetchSection2Output = async (queryParams) =>
  handleQuery({
    resourceUrl: `/section2`,
    queryParams,
    apiService: "diagnosis-v2",
  });

/**
 * :param: user_id           //"1234"
 */
export const fetchSection3Output = async (queryParams) =>
  handleQuery({
    resourceUrl: `/section3`,
    queryParams,
    apiService: "diagnosis-v2",
  });

/* ---- Mutations ---- */
// {user_id: ""}
export const createDiagnosisUser = async (reqBody) =>
  handleMutation({
    method: "POST",
    resourceUrl: "/user/create",
    reqBody,
    apiService: "diagnosis-v2",
  });

/*
  {
      "user_id": "",
      "answer" : "" or ["", ""]
  }
   */

// {
//     "user_id" : str(user_id),
//     "section": 1 or 3,
//     "answers" : [
//         {
//             "question_number" : str(question_number),
//             "answer" : "a"
//         },
//     ]
// }
export const createDiagnosisAnswerForSec1or2 = async (reqBody) =>
  handleMutation({
    method: "POST",
    resourceUrl: "/answer/12/create",
    reqBody,
    apiService: "diagnosis-v2",
  });

// {
//     "user_id" : str(user_id),
//     "section" : 4 or 5,
//     "answer" : "[1,2,2]"
// }

export const createDiagnosisAnswerForSec3or4 = async (reqBody) =>
  handleMutation({
    method: "POST",
    resourceUrl: "/answer/34/create",
    reqBody,
    apiService: "diagnosis-v2",
  });

// :param: user

export const fetchChartComments = async (queryParams) =>
  handleQuery({
    resourceUrl: `/chartcomment/list`,
    queryParams,
    apiService: "diagnosis-v2",
  });

export const postComment = async (reqBody) =>
  handleMutation({
    method: "POST",
    resourceUrl: "/chartcomment/create",
    reqBody,
    apiService: "diagnosis-v2",
  });

export const updateComment = async (reqBody, id) =>
  handleMutation({
    method: "PUT",
    resourceUrl: `/chartcomment/${id}`,
    reqBody,
    apiService: "diagnosis-v2",
  });
