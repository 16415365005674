import { Table } from "antd";
import { useTranslation } from "react-i18next";

export default function BaseTable({ columns, data }) {
  const { t } = useTranslation();
  return (
    <Table
      columns={columns}
      dataSource={data}
      pagination={false} // Remove pagination logic
    />
  );
}
