import "normalize.css";
import "@shared/styles/index.css";
import "@shared/styles/index.scss";

import { ConfigProvider } from "antd";
import ja_JP from "antd/lib/locale/ja_JP";
import React from "react";
import ReactDOM from "react-dom";

import App from "./App";
import reportWebVitals from "./reportWebVitals";

{
  /* <React.StrictMode>
  </React.StrictMode>, */
}
ReactDOM.render(
  <ConfigProvider locale={ja_JP}>
    <App />
  </ConfigProvider>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
