import { StepBackwardOutlined, StepForwardOutlined } from "@ant-design/icons";
import { Button, Layout } from "antd";

import HeaderTitle from "./HeaderTitle";
import LogoutButton from "./LogoutButton";

const { Header } = Layout;

export default function TopHeader({ isCollapsed, onToggleCollapse }) {
  return (
    <Header
      style={{
        background: "#fff",
        padding: 0,
        paddingLeft: 16,
        paddingRight: 16,
      }}
    >
      <div
        style={{
          display: "inline-flex",
          // justifyContent: "space-between",
          alignItems: "center",
          width: "100%",
        }}
      >
        <Button
          type="secondary"
          icon={
            isCollapsed ? <StepForwardOutlined /> : <StepBackwardOutlined />
          }
          style={{ cursor: "pointer" }}
          onClick={onToggleCollapse}
          style={{ marginRight: "1rem" }}
        />

        <HeaderTitle />
        <LogoutButton />
      </div>
    </Header>
  );
}
