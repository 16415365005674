// ant-design/icons: Due to special use case with collapasible icon support
import {
  BarChartOutlined,
  RadarChartOutlined,
  ReadOutlined,
  UserOutlined,
  VideoCameraOutlined,
  TeamOutlined,
} from "@ant-design/icons";
import Logo from "@assets/images/logo.png";
import { Layout, Menu } from "antd";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useLocation } from "react-router-dom";

import styles from "./styles.module.scss";

const { Sider } = Layout;

export default function Sidebar({ isCollapsed, onCollapse }) {
  const { t } = useTranslation();
  const { pathname } = useLocation();

  /* ---- Handle matching sidebar highlighted tab on hard page refresh ---- */
  let key = 2;
  if (pathname === "/entrepreneurs" || pathname.includes("entrepreneur")) {
    key = 2;
  } else if (pathname === "/organizers" || pathname.includes("organizer")) {
    key = 1;
  } else if (pathname === "/founders" || pathname.includes("founder")) {
    key = 3;
  } else if (pathname === "/staffs") {
    key = 4;
  } else if (pathname === "/articles-blogs") {
    key = 5;
  } else if (pathname === "/videos") {
    key = 6;
  } else if (pathname === "/sales") {
    key = 7;
  } else if (pathname === "/diagnosis") {
    key = 8;
  } else if (pathname === "/news-topics" || pathname.includes("news-topic")) {
    key = 9;
  } else if (pathname === "/applications" || pathname.includes("application")) {
    key = 10;
  }

  return (
    <>
      <Sider
        collapsible
        collapsed={isCollapsed}
        onCollapse={onCollapse}
        trigger={null}
        style={{ backgroundColor: "#03355e" }}
      >
        {/* Hide the top logo, when sidebar is collapsed */}
        <div className={styles.logo}>
          {!isCollapsed && <img src={Logo} alt="Stabiz logo" />}
        </div>

        <Menu
          theme="dark"
          style={{ backgroundColor: "#03355e" }}
          mode="inline"
          defaultSelectedKeys={[`${key}`]}
        >
          {/* <Menu.Item key="1" icon={<HomeOutlined />}>
            <span>{t("Dashboard")}</span>
            <Link to="/dashboard" />
          </Menu.Item> */}
          <Menu.Item key="1" icon={<UserOutlined />}>
            <span>{t("Organizers")}</span>
            <Link to="/organizers" />
          </Menu.Item>
          <Menu.Item key="2" icon={<UserOutlined />}>
            <span>{t("Entrepreneurs")}</span>
            <Link to="/entrepreneurs" />
          </Menu.Item>
          <Menu.Item key="3" icon={<UserOutlined />}>
            <span>{t("Founders")}</span>
            <Link to="/founders" />
          </Menu.Item>
          {/* <Menu.Item key="4" icon={<UserOutlined />}>
            <span>{t("Staffs")}</span>
            <Link to="/staffs" />
          </Menu.Item>
          <Menu.Item key="5" icon={<ReadOutlined />}>
            <span>{t("Article (Blogs)")}</span>
            <Link to="/articles-blogs" />
          </Menu.Item> */}
          {/* <Menu.Item key="6" icon={<VideoCameraOutlined />}>
            <span>{t("Videos")}</span>
            <Link to="/videos" />
          </Menu.Item>
          <Menu.Item key="7" icon={<BarChartOutlined />}>
            <span>{t("Sales")}</span>
            <Link to="/sales" />
          </Menu.Item>
          <Menu.Item key="8" icon={<RadarChartOutlined />}>
            <span>{t("Diagnosis")}</span>
            <Link to="/diagnosis" />
          </Menu.Item> */}
          <Menu.Item key="9" icon={<ReadOutlined />}>
            <span>{t("News")}</span>
            <Link to="/news-topics" />
          </Menu.Item>
          <Menu.Item key="10" icon={<TeamOutlined />}>
            <span>{t("Applications")}</span>
            <Link to="/applications" />
          </Menu.Item>
        </Menu>
      </Sider>
    </>
  );
}
