import { Skeleton, Table } from "antd";

export default function TableSkeleton({ columns, rowCount = 10 }) {
  return (
    <Table
      rowKey="key"
      pagination={false}
      dataSource={[...Array(rowCount)].map((_, index) => ({
        key: `key${index}`,
      }))}
      columns={columns.map((column) => {
        return {
          ...column,
          render: function renderPlaceholder() {
            return <Skeleton key={column.dataIndex} title paragraph={false} />;
          },
        };
      })}
    />
  );
}
