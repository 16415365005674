import i18n from "i18next";
/* ---- English Lang Translations ---- */
import en from "locales/en.json";
/* ---- Japanese translations ---- */
import jp from "locales/jp.json";
import { initReactI18next } from "react-i18next";

import { getDefaultLang, getLangFromUrlParams } from "./utils";

/**
 * Get the lang from url params: ?lang=en,
 * else jp on prod
 * en on local dev
 */
const currentLocalLang = getLangFromUrlParams() || getDefaultLang();

i18n.use(initReactI18next).init({
  lng: currentLocalLang,
  fallbackLng: "en",
  whitelist: ["en", "jp"],
  debug: false,
  ns: ["translation"],
  defaultNS: "translation",
  interpolation: {
    escapeValue: false,
  },
  resources: {
    en,
    jp,
  },
});

export default i18n;
