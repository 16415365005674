import {
  Cell,
  Label,
  PolarAngleAxis,
  PolarGrid,
  PolarRadiusAxis,
  Radar,
  RadarChart,
  ResponsiveContainer,
  Tooltip,
} from "recharts";

const data = [
  {
    sub_cat: "Leadership and traction",
    score: -5,
    domain: [0, 150],
  },
  {
    sub_cat: "Flexibility, response power",
    score: 98,
    domain: [0, 150],
  },
  {
    sub_cat: "Relationship structure",
    score: 86,
    domain: [0, 150],
  },
  {
    sub_cat: "Customer-oriented",
    score: 99,
    domain: [0, 150],
  },
  {
    sub_cat: "Action based on belief",
    score: 85,
    domain: [0, 150],
  },
  {
    sub_cat: "Unique",
    score: 85,
    domain: [0, 150],
  },
  {
    sub_cat: "leadership",
    score: 79,
    domain: [0, 150],
  },
  {
    sub_cat: "leadershipasdsa",
    score: 79,
    domain: [0, 150],
  },
  {
    sub_cat: "leadershipe",
    score: 79,
    domain: [0, 150],
  },
  {
    sub_cat: "leadershipd",
    score: 79,
    domain: [0, 150],
  },
  {
    sub_cat: "leadershipb",
    score: 79,
    domain: [0, 150],
  },
  {
    sub_cat: "leadershipa",
    score: 79,
    domain: [0, 150],
  },
];

export function Section1Middle({ data, maxScore }) {
  return (
    <div>
      <p className="text-center text-xs">能力・特性</p>

      <ResponsiveContainer height={320} width={380}>
        <RadarChart
          cx="50%"
          cy="50%"
          data={data}
          margin={{ top: 0, right: 0, bottom: 0, left: 0 }}
          outerRadius="80%"
        >
          <Tooltip />
          <PolarGrid />
          <PolarAngleAxis
            dataKey="sub_cat"
            tick={{ fontSize: "9px", letterSpacing: "-.35px" }}
          />
          <PolarRadiusAxis
            angle={90}
            axisLine={false}
            domain={[0, maxScore]}
            tick={false}
          />
          <Radar
            dataKey="score"
            fill="#F23BF2"
            // fillOpacity={0.5}
            // stroke="#3B82F6"
          />
        </RadarChart>
      </ResponsiveContainer>
    </div>
  );
}
