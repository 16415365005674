import cx from "clsx";
import { CgSpinner } from "react-icons/cg";

export default function LoadingSpinner({
  size = "text-2xl",
  fillColor = "text-white",
}) {
  // TODO: Write docs

  return (
    <CgSpinner className={cx("fill-current animate-spin", size, fillColor)} />
  );
}
