import { TopHeader } from "@shared/components";
import { Layout } from "antd";
import { useState } from "react";

import Sidebar from "./Sidebar";

export default function BaseLayout({ children }) {
  const [isCollapsed, setIsCollapsed] = useState(true);

  const onCollapse = (collapsed) => {
    setIsCollapsed(collapsed);
  };

  const handleToggleCollapse = () => {
    setIsCollapsed(!isCollapsed);
  };

  return (
    <Layout style={{ minHeight: "100vh" }}>
      <Sidebar isCollapsed={isCollapsed} onCollapse={onCollapse} />
      <Layout style={{ position: "stickey" }}>
        <TopHeader
          isCollapsed={isCollapsed}
          onToggleCollapse={handleToggleCollapse}
        />
        {children}
      </Layout>
    </Layout>
  );
}
