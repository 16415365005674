import { TopProgressBar } from "@shared/components";
import { AppProviders } from "@shared/contexts/app-providers";
import { lazy, Suspense } from "react";
import { Route } from "react-router-dom";
import ScrollMemory from "react-router-scroll-memory";

/* ---- Lazy Load Routes ---- */
const PrivateRoute = lazy(() => import("./pages/PrivateRoute"));
const AuthenticatedApp = lazy(() => import("./pages/AuthenticatedApp"));
// ---
const Login = lazy(() => import("./pages/Login/Login"));

export default function App() {
  return (
    <AppProviders>
      <ScrollMemory />
      <Suspense fallback={<TopProgressBar />}>
        <Route exact path="/login" component={Login} />
        <PrivateRoute exact path="/" component={AuthenticatedApp} />
      </Suspense>
    </AppProviders>
  );
}
