import {
  Bar,
  BarChart,
  CartesianGrid,
  Cell,
  Legend,
  ReferenceLine,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";

export function Section3({
  data,
  firstDataKey = "1_score",
  secondDataKey = "2_score",
  yDataKey = "name",
}) {
  return (
    <BarChart
      data={data}
      height={320}
      layout="vertical"
      margin={{ top: 5, bottom: 5, left: 50, right: 10 }}
      stackOffset="sign"
      width={320}
    >
      <CartesianGrid strokeDasharray="3 3" />

      <XAxis type="number" />
      <YAxis dataKey={yDataKey} fontSize="8" type="category" />
      <Tooltip />
      {/* <Legend style={{ marginTop: "10px" }} /> */}
      <ReferenceLine stroke="#000" x={0} />
      <Bar dataKey={firstDataKey} fill="#26C6DA" stackId="stack" />
      <Bar dataKey={secondDataKey} fill="#F23BF2" stackId="stack" />
    </BarChart>
  );
}
