import i18nConfig from "@shared/utils/i18n";
import { I18nextProvider } from "react-i18next";
import { QueryClient, QueryClientProvider } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";
import { BrowserRouter as Router } from "react-router-dom";

import { AuthUserProvider } from "./auth-user.context";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: false,
    },
  },
});

function AppProviders({ children }) {
  return (
    <I18nextProvider i18n={i18nConfig}>
      <QueryClientProvider client={queryClient}>
        <Router>
          <AuthUserProvider>{children}</AuthUserProvider>
        </Router>
        <ReactQueryDevtools initialIsOpen={false} />
      </QueryClientProvider>
    </I18nextProvider>
  );
}
{
  /* <ReactQueryDevtools initialIsOpen={false} /> */
}

export { AppProviders };
