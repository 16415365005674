import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";

export default function HeaderTitle() {
  const { t } = useTranslation();
  const { pathname } = useLocation();

  const currentRoute = getRouteName(pathname);

  return <h3>{t(currentRoute)}</h3>;
}

function getRouteName(currentPath) {
  if (currentPath === "/entrepreneurs") return "Entrepreneurs";
  if (currentPath === "/founders") return "Founders";
  if (currentPath === "/organizers") return "Organizers";
  if (currentPath === "/applications") return "Applications";
  if (currentPath === "/staffs") return "Staffs";
  if (currentPath === "/news-topics") return "News and Topics";
  if (currentPath === "/articles-blogs") return "Articles (Blogs)";
  if (currentPath === "/sales") return "Sales";
  if (currentPath === "/videos") return "Videos";
  if (currentPath === "/diagnosis") return "Diagnosis";

  return "";
}
