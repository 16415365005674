/**
 * Trim all the values in an object
 * Used clearning whitespaces in user forminputs from start and end
 */
import { format } from "date-fns";

export const trimWhitespacesFromInput = (formValues) => {
  return Object.keys(formValues).reduce((acc, key) => {
    acc[key] = formValues[key].trim();
    return acc;
  }, {});
};

/**
 * Check if an object is empty
 * Usage: to check form error object is empty
 */
export const checkIsObjectEmpty = (obj) => Object.keys(obj).length === 0;

export const setLocalToken = (tokenType, token) => {
  if (tokenType === "auth-user") {
    // remove exisitng token,
    localStorage.removeItem("cms-user");
    // set new token
    return localStorage.setItem("cms-user", token);
  }
  if (tokenType === "auth-access") {
    localStorage.removeItem("cms-auth-access");
    return localStorage.setItem("cms-auth-access", token);
  }
  if (tokenType === "locale") {
    return localStorage.setItem("cms-locale", token);
  }
};

/* ---- Get token from localStorage ---- */
export const getLocalToken = (tokenType) => {
  if (tokenType === "auth-access") {
    return localStorage.getItem("cms-auth-access");
  }
  if (tokenType === "auth-user") {
    return localStorage.getItem("cms-user");
  }

  if (tokenType === "locale") {
    return localStorage.getItem("cms-locale");
  }
};

/* ---- Remove both auth tokens from local storage ---- */
export const deleteLocalAuthTokens = () => {
  localStorage.removeItem("cms-auth-access");
  localStorage.removeItem("cms-user");
};

/* ---- Create an array of validation messages from the nested errors object from the backend---- */
/* {messaage, errors: {key: [..,..]}} */
/**
 * Create an array of validation messages from the nested errors object from the backend
 * recieved format from backend: {messaage, errors: {key: ["", "", ,..]}}
 * output: ["", "", ...]
 */
export const createValidationErrMessages = (validationeErrorsObj) => {
  const messages = Object.keys(validationeErrorsObj)
    .map((err) => validationeErrorsObj[err])
    .flat();
  return messages;
};

/**
 * Get the lang from url params: ?lang=en ?lang=jp
 * else default jp
 */
export const getLangFromUrlParams = () => {
  const urlParams = new URLSearchParams(window.location.search);
  const langParam = urlParams.get("lang");

  return langParam;
};

/* ---- Toggle functionality depending on url ---- */
// (Used mainly for language toggle disable on client prod)
/**
 * Toggle functionality depending on url environment
 * Used feature toggle based local or prod  prod
 */
export const isDevelopmentMode = () => {
  return ["localhost", "127.0.0.1"].includes(window.location.hostname);
};

/**
 * On developement mode, default lang en
 * On production server, default lang jp
 * unless overridden by ?lang= params
 */
export const getDefaultLang = () => {
  const isDevMode = isDevelopmentMode();
  return isDevMode ? "jp" : "jp";
};

/**
 * Compare newData with oldData
 * to check for changed values
 * If data-points didn't exist in old set, in skips
 * Used for: passing only changed field values while updating
 */
export const diffBetweenObjects = (oldData, newData) => {
  const filteredData = Object.keys(newData).reduce((diff, key) => {
    // if (!(key in oldData)) return diff;

    // Skip matched items
    if (oldData[key] === newData[key]) return diff;

    return {
      ...diff,
      [key]: newData[key],
    };
  }, {});
  return filteredData;
};

export const createValueLabelArr = (
  dataArr,
  labelKey = "label",
  valueKey = "id"
) => {
  if (dataArr) {
    return dataArr.map((data) => {
      return {
        value: data[valueKey],
        label: data[labelKey],
      };
    });
  }
};

export const createIncomeValueList = (incomeValues) => {
  return incomeValues
    .map((income) => {
      let label;

      // if no lower_limit, means this is the lowest
      if (!income.lower_limit) {
        label = `${income.upper_limit}${income.unit}以下`;
      } else if (!income.upper_limit) {
        // this is the hightest
        label = `${income.lower_limit}${income.unit}以上`;
      } else {
        label = `${income.lower_limit} - ${income.upper_limit}${income.unit}`;
      }

      return {
        value: income.id,
        label,
      };
    })
    .sort((a, b) => a.value - b.value);
};

export const formatDate = (date, dateFormat = "yyyy/MM/dd") => {
  if (!date) {
    return null;
  }

  return format(new Date(date), dateFormat);
};

export const formatIncomeRange = (incomeRange, suffix = "万円") => {
  if (
    (!incomeRange?.upper_limit && !incomeRange?.lower_limit) ||
    !incomeRange
  ) {
    return "—";
  }

  let str;

  if (!incomeRange.upper_limit) {
    str = incomeRange.lower_limit;
  }

  if (!incomeRange.lower_limit) {
    str = incomeRange.upper_limit;
  }
  if (incomeRange.lower_limit && incomeRange.upper_limit) {
    str = `${incomeRange.lower_limit} - ${incomeRange.upper_limit}`;
  }

  str = `${str}${suffix}`;

  return str;
};
