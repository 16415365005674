export const getBarFillColor = (value) => {
  if (value > 0) {
    return "#3B82F6";
  }

  return "#F87CCC";
};

/**
 * {total_other_passive: 93
total_other_positive: 49
total_self_passive: 83
total_self_positive: 61}
* turn this into
* {x(total_self_positive), y(total_self_passive)} ...
 */
export const transformDataForQuadrantChart = (data) => {
  const resArr = [];

  resArr.push({
    x: data.x,
    y: data.y,
  });

  return resArr;
};

export const transformDataForSection1MiddleChart = (dataArr) => {
  const maxScore = dataArr[0]?.max_score;

  return dataArr.map((data) => {
    return {
      sub_cat: data.sub_cat,
      score: data.score,
      domain: [-maxScore, maxScore],
    };
  });
};

// const data = [
//   {
//     country: "Asia",
//     year: "1750",
//     value: 502,
//   },
//   {
//     country: "Asia",
//     year: "1800",
//     value: 635,
//   },
//   {
//     country: "Asia",
//     year: "1850",
//     value: 809,
//   },
//   {
//     country: "Asia",
//     year: "1900",
//     value: 947,
//   },
//   {
//     country: "Asia",
//     year: "1950",
//     value: 1402,
//   },
//   {
//     country: "Asia",
//     year: "1999",
//     value: 3634,
//   },
//   {
//     country: "Asia",
//     year: "2050",
//     value: 5268,
//   },
//   {
//     country: "Africa",
//     year: "1750",
//     value: 106,
//   },
//   {
//     country: "Africa",
//     year: "1800",
//     value: 107,
//   },
//   {
//     country: "Africa",
//     year: "1850",
//     value: 111,
//   },
//   {
//     country: "Africa",
//     year: "1900",
//     value: 133,
//   },
//   {
//     country: "Africa",
//     year: "1950",
//     value: 221,
//   },
//   {
//     country: "Africa",
//     year: "1999",
//     value: 767,
//   },
//   {
//     country: "Africa",
//     year: "2050",
//     value: 1766,
//   },
// ];

// [
//   {
//     name: "コミュニケーション能力",
//     "1_score": 1,
//     "2_score": -2,
//     "1_name": "全体最適",
//     "2_name": "個別最適",
//   },
//   {
//     name: "管理能力",
//     "1_score": 1,
//     "2_score": -1,
//     "1_name": "全体最適",
//     "2_name": "個別最適",
//   },
//   {
//     name: "調整能力",
//     "1_score": -2,
//     "2_score": 1,
//     "1_name": "全体最適",
//     "2_name": "個別最適",
//   },
//   {
//     name: "リーダーシップ",
//     "1_score": -1,
//     "2_score": 1,
//     "1_name": "全体最適",
//     "2_name": "個別最適",
//   },
//   {
//     name: "企画力",
//     "1_score": -2,
//     "2_score": -1,
//     "1_name": "全体最適",
//     "2_name": "個別最適",
//   },
//   {
//     name: "具現化力",
//     "1_score": 1,
//     "2_score": -1,
//     "1_name": "全体最適",
//     "2_name": "個別最適",
//   },
//   {
//     name: "実行力",
//     "1_score": 1,
//     "2_score": -1,
//     "1_name": "全体最適",
//     "2_name": "個別最適",
//   },
// ];
