import {
  Cell,
  Label,
  PolarAngleAxis,
  PolarGrid,
  PolarRadiusAxis,
  Radar,
  RadarChart,
  ResponsiveContainer,
  Tooltip,
} from "recharts";

export function Section1Minor({ data, maxScore }) {
  return (
    <div>
      <p className="text-center text-xs">志向性</p>

      <ResponsiveContainer height={320} width={400}>
        <RadarChart
          cx="50%"
          cy="50%"
          data={data}
          margin={{ top: 0, right: 0, bottom: 0, left: 0 }}
          outerRadius="80%"
        >
          <Tooltip />
          <PolarGrid />
          <PolarAngleAxis
            dataKey="sub_cat"
            tick={{ fontSize: "9px", letterSpacing: "-.35px" }}
          />
          <PolarRadiusAxis angle={90} domain={[0, maxScore]} tick={false} />
          <Radar dataKey="score" fill="#26C6DA" />
        </RadarChart>
      </ResponsiveContainer>
    </div>
  );
}
