import { useTranslation } from "react-i18next";
import {
  CartesianGrid,
  LabelList,
  Legend,
  ReferenceLine,
  Scatter,
  ScatterChart,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";

export function Section2({ data, yKey, xKey }) {
  const { t } = useTranslation();

  return (
    <div>
      <p className="text-center text-xs">リーダーシップタイプ</p>

      <div className="flex">
        <div className="tracking-[1px] flex flex-col justify-around text-xs">
          <p style={{ writingMode: "vertical-rl", textOrientation: "upright" }}>
            {t("Positive")}
          </p>
          <p style={{ writingMode: "vertical-rl", textOrientation: "upright" }}>
            {t("Passive")}
          </p>
        </div>
        <ScatterChart
          height={200}
          margin={{ top: 20, right: 20, bottom: 20, left: 20 }}
          width={240}
        >
          <CartesianGrid />
          <XAxis
            hide
            dataKey={xKey}
            domain={[-200, 200]}
            name="Positive/Passive"
            type="number"
          />
          <YAxis
            hide
            dataKey={yKey}
            domain={[-200, 200]}
            name="Others/Self"
            type="number"
          />
          <Scatter data={data} fill="#B027B0" />
          {/* <Tooltip cursor={{ strokeDasharray: "2 2" }} /> */}
          <ReferenceLine stroke="#000000" y={0} />
          <ReferenceLine stroke="#000000" x={0} />
          {/* <Legend /> */}
        </ScatterChart>
      </div>
      <div className="tracking-[1px] flex justify-around text-xs">
        <p>{t("Others")}</p>
        <p>{t("Self")}</p>
      </div>
    </div>
  );
}
