import { Input } from "antd";
import { useTranslation } from "react-i18next";

const { Search } = Input;

export default function SearchInput({ placeholder }) {
  const { t } = useTranslation();

  const onSearch = (value) => console.log(value);
  return (
    <Search placeholder={t(placeholder)} onSearch={onSearch} enterButton />
  );
}
