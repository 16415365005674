import { SearchOutlined } from "@ant-design/icons";
import { Collapse } from "antd";
import { useTranslation } from "react-i18next";

const { Panel } = Collapse;

export default function SearchPanel({
  onReset,
  onSearch,
  onSearchInputChange,
  isSearchOn,
  inputValues,
  searchComponent,
  currCollpaseKey,
  setCurrCollpaseKey,
  clearFilter,
}) {
  // TODO: Write docs
  const Component = searchComponent;
  const { t } = useTranslation();

  function callback(key) {
    setCurrCollpaseKey(key);
  }

  return (
    <Collapse activeKey={currCollpaseKey} onChange={callback}>
      <Panel
        header={
          <div className="flex items-center">
            <SearchOutlined />
            <span style={{ marginLeft: "8px", display: "block" }}>
              {t("Search")}
            </span>
          </div>
        }
        key="search"
      >
        <Component
          inputValues={inputValues}
          isSearchOn={isSearchOn}
          onReset={onReset}
          onSearch={onSearch}
          onSearchInputChange={onSearchInputChange}
          clearFilter={clearFilter}
        />
      </Panel>
    </Collapse>
  );
}
