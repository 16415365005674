import cx from "clsx";

export function Section1Major({ data }) {
  return (
    <div className="flex mt-24 w-60 -space-x-24">
      {data.map((item, idx) => (
        <ProgressBar
          key={idx}
          category={item.sub_cat}
          color={[1, 3].includes(idx) ? "blue" : "red"}
          currValue={item.score}
          maxValue={item.max_score}
        />
      ))}
    </div>
  );
}

function ProgressBar({ color, maxValue, currValue, category }) {
  return (
    <div>
      <progress
        className={cx(
          color === "red" && "redProgress",
          color === "blue" && "blueProgress",
          "-rotate-90"
        )}
        max={maxValue}
        value={currValue}
      />
      <div className="mt-24 text-center">
        <p className="text-xs">{category}</p>
        <p className="text-2xl">{currValue}</p>
      </div>
    </div>
  );
}
