import {
  getDefaultLang,
  getLangFromUrlParams,
  getLocalToken,
} from "@shared/utils";
import axios from "axios";

import {
  API_ENDPOINT,
  DIAGNOSIS_API_ENDPOINT,
  DIAGNOSIS_API_TOKEN,
} from "../../config";

/* ---- Base url, main-service/diagnosis-service ---- */
const getBaseUrl = (service) => {
  if (service === "diagnosis") {
    return DIAGNOSIS_API_ENDPOINT;
  }

  if (service === "diagnosis-v2") {
    return DIAGNOSIS_API_ENDPOINT;
  }

  return API_ENDPOINT;
};

// Axios instance
const api = axios.create();

// Global interceptor for auth token
api.interceptors.request.use((config) => {
  // Skip adding header in login request
  const authToken = getLocalToken("auth-access");
  if (!config.url.includes("/login") && !!authToken) {
    config.headers.Authorization = `Bearer ${authToken}`;
  }

  if (config.url?.includes(DIAGNOSIS_API_ENDPOINT)) {
    // eslint-disable-next-line no-param-reassign
    config.headers.Authorization = DIAGNOSIS_API_TOKEN;
  }

  // Pass lang header
  // Get from lang header, or default based on enviroment
  config.headers.lang = getLangFromUrlParams() || getDefaultLang();

  // Fix the missmatch between the previous jp and current ja backend locale
  if (config.headers.lang === "jp") {
    config.headers.lang = "ja";
  }

  return config;
});

/* ---- Base function to get data ---- */
export const handleQuery = async ({ resourceUrl, queryParams, apiService }) => {
  // get base Url based on service
  const baseUrl = getBaseUrl(apiService);

  try {
    const response = await api.request({
      method: "GET",
      url: `${baseUrl}${resourceUrl}/`,
      ...(queryParams && { params: { ...queryParams } }), // Pass params if there's query object passed
    });
    return Promise.resolve(response.data);
  } catch (error) {
    return Promise.reject(error.response);
  }
};

/* ---- Base function for Data mutation requests -> POST, PUT, PATCH, DELETE ---- */
export const handleMutation = async ({
  resourceUrl,
  method,
  reqBody,
  apiService,
}) => {
  // Check if the passed method: POST, PUT, PATCH, DELETE
  if (!["POST", "PUT", "DELETE", "PATCH"].includes(method)) {
    return Promise.reject(new Error("Pass a valid method"));
  }

  // get base Url based on service
  const baseUrl = getBaseUrl(apiService);

  try {
    const response = await api.request({
      method: `${method}`,
      url: `${baseUrl}${resourceUrl}/`,
      ...(reqBody && { data: reqBody }),
    });
    return Promise.resolve(response.data);
  } catch (error) {
    return Promise.reject(error.response);
  }
};
