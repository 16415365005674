import { createContext, useContext, useMemo, useState } from "react";

const AuthUserStateContext = createContext();

const AuthUserProvider = ({ children }) => {
  const [authUser, setAuthUser] = useState(null);

  const authUserState = useMemo(
    () => ({
      authUser,
      setAuthUser,
    }),
    [authUser, setAuthUser]
  );

  return (
    <AuthUserStateContext.Provider value={authUserState}>
      {children}
    </AuthUserStateContext.Provider>
  );
};

const useAuthUser = () => {
  const context = useContext(AuthUserStateContext);
  if (context === undefined) {
    throw new Error(`useAuthUser must be used within a AuthUserProvider`);
  }
  return context;
};

export { AuthUserProvider, useAuthUser };
