import { Button, Input, message } from "antd";
import cx from "clsx";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useMutation, useQuery, useQueryClient } from "react-query";

import { LoadingSpinner } from "@/shared/components";

import {
  fetchChartComments,
  fetchDiagnosisUser,
  fetchSection1Output,
  fetchSection2Output,
  fetchSection3Output,
  postComment,
  updateComment,
} from "./diagnosis.service";
import { DiagnosisContainer } from "./DiagnosisContainer";
import { Section1Major } from "./old/Section1Major";
import { Section3 } from "./old/Section3";
import { Section1Middle } from "./Section1Middle";
import { Section1Minor } from "./Section1Minor";
import { Section2 } from "./Section2";
import {
  transformDataForQuadrantChart,
  transformDataForSection1MiddleChart,
} from "./utils";

const { TextArea } = Input;

export default function ResultCharts({ userDetails }) {
  const [display, setDisplay] = useState(false);
  const { isLoading, error, data } = useQuery("diagnosisUser", () =>
    fetchDiagnosisUser({ userId: userDetails.id })
  );
  useEffect(() => {
    if (data?.current_section == "0" && !error) {
      setDisplay(true);
    }
  }, [data]);

  const { t } = useTranslation();

  const [comment1, setComment1] = useState("");
  const [comment2, setComment2] = useState("");
  const [comment3, setComment3] = useState("");
  const [comment4, setComment4] = useState("");
  const [comment5, setComment5] = useState("");

  const [id, setId] = useState(null);

  const [update, setUpdate] = useState(false);

  const section1MajorOutputResult = useQuery("section1MajorOutput", () =>
    fetchSection1Output({
      user_id: userDetails.id,
      category: "major",
    })
  );
  const section1MiddleOutputResult = useQuery("section1MiddleOutput", () =>
    fetchSection1Output({
      user_id: userDetails.id,
      category: "middle",
    })
  );
  const section1MinorOutputResult = useQuery("section1MinorOutput", () =>
    fetchSection1Output({
      user_id: userDetails.id,
      category: "minor",
    })
  );
  const section2OutputResult = useQuery("section2Output", () =>
    fetchSection2Output({ user_id: userDetails.id })
  );

  const section3OutputResult = useQuery("section3Output", () =>
    fetchSection3Output({ user_id: userDetails.id })
  );

  const commentsList = useQuery(
    "commentslist",
    () => fetchChartComments({ user: userDetails.id }),
    {
      onSuccess: (data) => {
        if (data.length !== 0) {
          setComment1(data[0].comment_section1major);
          setComment2(data[0].comment_section2);
          setComment3(data[0].comment_section1middle);
          setComment4(data[0].comment_section1minor);
          setComment5(data[0].comment_section3);
          setUpdate(true);
          setId(data[0].id);
        }
        // console.log(data);
      },
    }
  );

  // console.log(commentsList);

  const commentMutation = useMutation((data) => postComment(data), {
    onSuccess: () => {
      message.success(t("the comment was updated successfully"));
    },
    onError: (error) => {
      message.error(t("Something went wrong"));
    },
  });

  const commentUpdateMutation = useMutation((data) => updateComment(data, id), {
    onSuccess: () => {
      message.success(t("the comment was updated successfully"));
    },
    onError: (error) => {
      message.error(t("Something went wrong"));
    },
  });

  const onSubmit = (e) => {
    e.preventDefault();
    if (update === true) {
      commentUpdateMutation.mutate({
        user: userDetails.id,
        comment_section1major: comment1,
        comment_section1middle: comment3,
        comment_section1minor: comment4,
        comment_section2: comment2,
        comment_section3: comment5,
      });
    } else {
      commentMutation.mutate({
        user: userDetails.id,
        comment_section1major: comment1,
        comment_section1middle: comment3,
        comment_section1minor: comment4,
        comment_section2: comment2,
        comment_section3: comment5,
      });
    }
  };

  return (
    <>
      {display == true ? (
        <div className="w-[48rem] mb-20 bg-white bg-opacity-80">
          <div className="border-[#707070] py-4 border space-y-12">
            {/* <ChartContainer
            chart={section1MajorOutputResult.data && <Section1Major />}
          /> */}
            {/* chart1 */}
            <div className="flex justify-between px-8">
              <div className={cx("flex justify-center", false && "px-12")}>
                {section1MajorOutputResult.data && (
                  <Section1Major data={section1MajorOutputResult.data} />
                )}
              </div>
              <div className="border-[#707070] px-4 py-2 w-1/2 border">
                <TextArea
                  rows={4}
                  value={comment1}
                  onChange={(e) => setComment1(e.target.value)}
                />
              </div>
            </div>
            {/* chart2 */}
            <div className="flex justify-between px-8">
              <div className={cx("flex justify-center", true && "px-12")}>
                {section2OutputResult.data && (
                  <Section2
                    data={transformDataForQuadrantChart(
                      section2OutputResult.data
                    )}
                    xKey="x"
                    yKey="y"
                  />
                )}
              </div>
              <div className="border-[#707070] px-4 py-2 w-1/2 border">
                <TextArea
                  rows={4}
                  value={comment2}
                  onChange={(e) => setComment2(e.target.value)}
                />
              </div>
            </div>
            {/* chart3 */}

            <div className="flex justify-between px-8">
              <div className={cx("flex justify-center", true && "px-12")}>
                {section1MiddleOutputResult.data && (
                  <Section1Middle
                    data={transformDataForSection1MiddleChart(
                      section1MiddleOutputResult.data
                    )}
                    maxScore={section1MiddleOutputResult.data[0]?.max_score}
                  />
                )}
              </div>
              <div className="border-[#707070] px-4 py-2 w-1/2 border">
                <TextArea
                  rows={4}
                  value={comment3}
                  onChange={(e) => setComment3(e.target.value)}
                />
              </div>
            </div>
            {/* chart4 */}

            <div className="flex justify-between px-8">
              <div className={cx("flex justify-center", true && "px-12")}>
                {section1MinorOutputResult.data && (
                  <Section1Minor
                    data={transformDataForSection1MiddleChart(
                      section1MinorOutputResult.data
                    )}
                    maxScore={section1MinorOutputResult.data[0]?.max_score}
                  />
                )}
              </div>
              <div className="border-[#707070] px-4 py-2 w-1/2 border">
                <TextArea
                  rows={4}
                  value={comment4}
                  onChange={(e) => setComment4(e.target.value)}
                />
              </div>
            </div>

            {/* chart5 */}

            <div className="flex justify-between px-8">
              <div className={cx("flex justify-center", false && "px-12")}>
                <Section3
                  data={section3OutputResult.data}
                  firstDataKey="1_score"
                  secondDataKey="2_score"
                  yDataKey="name"
                />
              </div>
              <div className="border-[#707070] px-4 py-2 w-1/2 border">
                <TextArea
                  rows={4}
                  value={comment5}
                  onChange={(e) => setComment5(e.target.value)}
                />
              </div>
            </div>
          </div>
          <div className="flex" style={{ marginTop: "2rem" }}>
            <Button
              type="primary"
              style={{ marginLeft: "auto" }}
              onClick={onSubmit}
            >
              {t("Submit")}
            </Button>
          </div>
        </div>
      ) : (
        <h3>{t("no result data")}</h3>
      )}
    </>
  );
}

function Loader() {
  return (
    <div className="flex items-center justify-center mx-auto px-4 max-w-6xl h-52 md:px-8">
      <LoadingSpinner fillColor="text-primary" size="text-5xl" />
    </div>
  );
}

function ChartContainer({ chart, hasPadding = true }) {
  return (
    <div className="flex justify-between px-8">
      <div className={cx("flex justify-center", hasPadding && "px-12")}>
        {chart}
      </div>
      <div className="border-[#707070] px-4 py-2 w-1/2 border">
        <TextArea rows={4} />
      </div>
    </div>
  );
}
